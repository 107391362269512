import CardsAll from './assets/all-cards.svg';
import AmateurLeagueIcon from './assets/amateur-league-icon.svg';
import Amateur from './assets/amateur.svg';
import ArrowDownCircle from './assets/arrow-down-circle.svg';
import ArrowDown from './assets/arrow-down.svg';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';
import ArrowUpCircle from './assets/arrow-up-circle.svg';
import ArrowUp from './assets/arrow-up.svg';
import Arrow from './assets/arrow.svg';
import ArrowHeadInverse from './assets/arrowhead-inverse.svg';
import ArrowHead from './assets/arrowhead.svg';
import BallInverse from './assets/ball-inverse.svg';
import BallActive from './assets/ball.svg';
import BeginnerLeagueIcon from './assets/beginner-league-icon.svg';
import Btc from './assets/btc.svg';
import CancelInverse from './assets/cancel-inverse.svg';
import CancelIcon from './assets/cancel.svg';
import CardsAuction from './assets/cards-auction.svg';
import CardsNotSale from './assets/cards-no-sale.svg';
import CardsSale from './assets/cards-sale.svg';
import ChatAccent from './assets/chat-accent.svg';
import Chat from './assets/chat.svg';
import CheckWhite from './assets/check-white.svg';
import ChevronDown from './assets/chevron-down.svg';
import ChevronRight from './assets/chevron-right.svg';
import ChevronUp from './assets/chevron-up.svg';
import CopyIconAccent from './assets/copy-accent.svg';
import CopyIconInverse from './assets/copy-inverse.svg';
import CopyIcon from './assets/copy.svg';
import CupCircle from './assets/cup-circle.svg';
import Cup from './assets/cup.svg';
import DailyPrize from './assets/daily-prize.png';
import DiamondActive from './assets/diamond-active.svg';
import DiamondDefault from './assets/diamond-default.svg';
import EqualSign from './assets/equal.svg';
import Eth from './assets/eth.svg';
import EuroTicket from './assets/euro-ticket.svg';
import Euro from './assets/euro.svg';
import Exit from './assets/exit.svg';
import FilterActive from './assets/filter-active.svg';
import Filters from './assets/filter.svg';
import Friends from './assets/friends.svg';
import FTToken from './assets/ft-token.png';
import Fton from './assets/fton.svg';
import Gate from './assets/gate.svg';
import Gift from './assets/gift.svg';
import HomeActive from './assets/home-active.svg';
import HomeIcon from './assets/home.svg';
import InfoReverse from './assets/info-inverse.svg';
import InfoSecondary from './assets/info-secondary.svg';
import Info from './assets/info.svg';
import InviteFriend from './assets/invite-friend-inverse.svg';
import Kucoin from './assets/kucoin.svg';
import LegendaryLeagueIcon from './assets/legendary-league-icon.svg';
import Lock from './assets/lock.svg';
import LogoAccent from './assets/logo-accent.svg';
import Logo from './assets/logo-default.svg';
import LootBox from './assets/lootbox.svg';
import MessiCard from './assets/messi-card.png';
import MessiReal from './assets/messi-scoring.gif';
import Mexc from './assets/mexc.svg';
import NFTAmateurLeague from './assets/nft-amateur-league.svg';
import NFTBeginnerLeague from './assets/nft-beginner-league.svg';
import NFTLegendaryLeague from './assets/nft-legendary-league.svg';
import NFTLiteLeague from './assets/nft-lite-league.svg';
import NFTMixZoneLeague from './assets/nft-mixzone-league.svg';
import NFTProLeague from './assets/nft-pro-league.svg';
import NFTSemiProLeague from './assets/nft-semi-pro-league.svg';
import OtherWalletLogo from './assets/other-wallet-logo.svg';
import PlaneActive from './assets/plane-active.svg';
import PlaneIcon from './assets/plane.svg';
import PlusInverse from './assets/plus-inverse.svg';
import PlusIcon from './assets/plus.svg';
import PremiumL from './assets/premiumL.svg';
import PremiumM from './assets/premiumM.svg';
import PremiumS from './assets/premiumS.svg';
import PrizeAccent from './assets/prize-accent.svg';
import PrizeCircle from './assets/prize-circle.svg';
import Prize from './assets/prize-default.svg';
import PrizeCardEpic from './assets/prizecard-epic.svg';
import PrizeCardLegendary from './assets/prizecard-legendary.svg';
import PrizeCardRare from './assets/prizecard-rare.svg';
import ProLeagueIcon from './assets/pro-league-icon.svg';
import Pro from './assets/pro.svg';
import PVP from './assets/pvp.svg';
import Question from './assets/question.svg';
import Recieve from './assets/recieve.svg';
import Send from './assets/send.svg';
import ShareAlt from './assets/share-alt.svg';
import Share from './assets/share.svg';
import Sucsess from './assets/ssuccess.svg';
import Stake from './assets/stake.svg';
import Star from './assets/star.svg';
import Ston from './assets/ston.svg';
import Swap from './assets/swap.svg';
import TelegramWalletLogo from './assets/telegram-wallet-logo.svg';
import TicketIcon from './assets/ticket.svg';
import Tickets from './assets/tickets.png';
import TilesAccent from './assets/tiles-accent.svg';
import Tiles from './assets/tiles.svg';
import Token from './assets/token.png';
import Ton from './assets/ton-black.svg';
import TonInverse from './assets/ton-inverse.svg';
import TonYellow from './assets/ton.svg';
import TonkeeperLogo from './assets/tonkeeper-logo.svg';
import TraingleBottom from './assets/triangle-bottom.svg';
import TrophyDefault from './assets/trophy-default.svg';
import Trophy from './assets/trophy.svg';
import TrustWalletLogo from './assets/trust-wallet-logo.svg';
import Usd from './assets/usd.svg';
import Usdt from './assets/usdt.svg';
import VariantsInverse from './assets/variants-inverse.svg';
import Variants from './assets/variants.svg';
import Video from './assets/video.png';
import CreditCard from './assets/wallet-card.svg';
import Winline from './assets/winline.png';
import Xtr from './assets/xtr.svg';

export type TIconsMapIconData = {
  default: string;
  accent?: string;
  inverse?: string;
  secondary?: string;
};

export enum EIconType {
  INFO = 'INFO',
  LOGO = 'LOGO',
  PRIZE = 'PRIZE',
  FRIENDS = 'FRIENDS',
  PRIZE_CIRCLE = 'PRIZE_CIRCLE',
  CUP_CIRCLE = 'CUP_CIRCLE',
  CUP = 'CUP',
  TILES = 'TILES',
  CHAT = 'CHAT',
  VARIANTS = 'VARIANTS',
  DIAMOND = 'DIAMOND',
  PLUS = 'PLUS',
  ARROW_UP_CIRCLE = 'ARROW_UP_CIRCLE',
  ARROW_DOWN_CIRCLE = 'ARROW_DOWN_CIRCLE',
  ARROW = 'ARROW',
  ARROW_UP = 'ARROW_UP',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_LEFT = 'ARROW_LEFT',
  TON = 'TON',
  BTC = 'BTC',
  ARROWHEAD = 'ARROWHEAD',
  TRUST_WALLET = 'TRUST_WALLET',
  OTHER_WALLET = 'OTHER_WALLET',
  COPY = 'COPY',
  ETH = 'ETH',
  USD = 'USD',
  USDT = 'USDT',
  XTR = 'XTR',
  CREDIT_CARD = 'CREDIT_CARD',
  HOME = 'HOME',
  PLANE = 'PLANE',
  TICKET = 'TICKET',
  MESSI_REAL = 'MESSI_REAL',
  MESSI_CARD = 'MESSI_CARD',
  EQ_SIGN = 'EQ_SIGN',
  INVITE_FRIEND = 'INVITE_FRIEND',
  TICKETS = 'TICKETS',
  SHARE = 'SHARE',
  NFT_BEGINNER_LEAGUE = 'NFT_BEGINNER_LEAGUE',
  NFT_AMATEUR_LEAGUE = 'NFT_AMATEUR_LEAGUE',
  NFT_PRO_LEAGUE = 'NFT_PRO_LEAGUE',
  NFT_LEGENDARY_LEAGUE = 'NFT_LEGENDARY_LEAGUE',
  NFT_LITE_LEAGUE = 'NFT_LITE_LEAGUE',
  NFT_SEMI_PRO_LEAGUE = 'NFT_SEMI_PRO_LEAGUE',
  NFT_MIX_ZONE_LEAGUE = 'NFT_MIX_ZONE_LEAGUE',
  TRAINGLE_BOTTOM = 'TRAINGLE_BOTTOM',
  PRO_LEAGUE_ICON = 'PRO_LEAGUE_ICON',
  BEGINNER_LEAGUE_ICON = 'BEGINNER_LEAGUE_ICON',
  AMATEUR_LEAGUE_ICON = 'AMATEUR_LEAGUE_ICON',
  LEGENDARY_LEAGUE_ICON = 'LEGENDARY_LEAGUE_ICON',
  TELEGRAM_WALLET = 'TELEGRAM_WALLET',
  BALL = 'BALL',
  TONKEEPER_LOGO = 'TONKEEPER_LOGO',
  EXIT = 'EXIT',
  CARDS_ALL = 'CARDS_ALL',
  CARDS_SALE = 'CARDS_SALE',
  CARDS_AUCTION = 'CARDS_AUCTION',
  CARDS_NOT_SALE = 'CARDS_NOT_SALE',
  FILTERS = 'FILTERS',
  CANCEL = 'CANCEL',
  SHARE_ALT = 'SHARE_ALT',
  PVP = 'PVP',
  PRO = 'PRO',
  AMATEUR = 'AMATEUR',
  PREMIUMS = 'PREMIUMS',
  PREMIUMM = 'PREMIUMM',
  PREMIUML = 'PREMIUML',
  TOKEN = 'TOKEN',
  FT_TOKEN = 'FT_TOKEN',
  FTON = 'FTON',
  TON_TOKEN = 'TON_TOKEN',
  STAR = 'STAR',
  TROPHY = 'TROPHY',
  PRIZE_CARD_EPIC = 'PRIZE_CARD_EPIC',
  PRIZE_CARD_RARE = 'PRIZE_CARD_RARE',
  PRIZE_CARD_LEGENDARY = 'PRIZE_CARD_LEGENDARY',
  SUCSESS = 'SUCSESS',
  VIDEO = 'VIDEO',
  LOOTBOX = 'LOOTBOX',
  EURO = 'EURO',
  LOCK = 'LOCK',
  QUESTION = 'QUESTION',
  GIFT = 'GIFT',
  EURO_TICKET = 'EURO_TICKET',
  RECIEVE = 'RECIEVE',
  SEND = 'SEND',
  SWAP = 'SWAP',
  STAKE = 'STAKE',
  CHEVRON_UP = 'CHEVRON_UP',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHECK_WHITE = 'CHECK_WHITE',
  WINLINE = 'WINLINE',
  DAILY_PRIZE = 'DAILY_PRIZE',
  STON = 'STON',
  KUCOIN = 'KUCOIN',
  MEXC = 'MEXC',
  GATE = 'GATE',
}

type TIconsMap = {
  [key in EIconType]: TIconsMapIconData;
};

export const iconsMap: TIconsMap = {
  [EIconType.LOGO]: {
    default: Logo,
    accent: LogoAccent,
  },
  [EIconType.PRIZE]: {
    default: Prize,
    accent: PrizeAccent,
  },
  [EIconType.TILES]: {
    default: Tiles,
    accent: TilesAccent,
  },
  [EIconType.CHAT]: {
    default: Chat,
    accent: ChatAccent,
  },
  [EIconType.VARIANTS]: {
    default: Variants,
    inverse: VariantsInverse,
  },
  [EIconType.DIAMOND]: {
    default: DiamondDefault,
    accent: DiamondActive,
  },
  [EIconType.PLUS]: {
    default: PlusIcon,
    inverse: PlusInverse,
  },
  [EIconType.ARROW_UP_CIRCLE]: {
    default: ArrowUpCircle,
  },
  [EIconType.ARROW_DOWN_CIRCLE]: {
    default: ArrowDownCircle,
  },
  [EIconType.ARROW]: {
    default: Arrow,
  },
  [EIconType.ARROW_UP]: {
    default: ArrowUp,
  },
  [EIconType.ARROW_RIGHT]: {
    default: ArrowRight,
  },
  [EIconType.ARROW_DOWN]: {
    default: ArrowDown,
  },
  [EIconType.ARROW_LEFT]: {
    default: ArrowLeft,
  },
  [EIconType.ARROWHEAD]: {
    default: ArrowHead,
    inverse: ArrowHeadInverse,
  },
  [EIconType.TON]: {
    default: Ton,
    inverse: TonInverse,
  },
  [EIconType.BTC]: {
    default: Btc,
  },
  [EIconType.TRUST_WALLET]: {
    default: TrustWalletLogo,
  },
  [EIconType.OTHER_WALLET]: {
    default: OtherWalletLogo,
  },
  [EIconType.COPY]: {
    default: CopyIcon,
    inverse: CopyIconInverse,
    accent: CopyIconAccent,
  },
  [EIconType.USD]: {
    default: Usd,
  },
  [EIconType.USDT]: {
    default: Usdt,
  },
  [EIconType.XTR]: {
    default: Xtr,
  },
  [EIconType.ETH]: {
    default: Eth,
  },
  [EIconType.CREDIT_CARD]: {
    default: CreditCard,
  },
  [EIconType.PRIZE_CIRCLE]: {
    default: PrizeCircle,
  },
  [EIconType.CUP_CIRCLE]: {
    default: CupCircle,
  },
  [EIconType.HOME]: {
    default: HomeIcon,
    accent: HomeActive,
  },
  [EIconType.PLANE]: {
    default: PlaneIcon,
    accent: PlaneActive,
  },
  [EIconType.TICKET]: {
    default: TicketIcon,
  },
  [EIconType.MESSI_CARD]: {
    default: MessiCard,
  },
  [EIconType.MESSI_REAL]: {
    default: MessiReal,
  },
  [EIconType.EQ_SIGN]: {
    default: EqualSign,
  },
  [EIconType.INVITE_FRIEND]: {
    default: InviteFriend,
    inverse: InviteFriend,
  },
  [EIconType.TICKETS]: {
    default: Tickets,
  },
  [EIconType.SHARE]: {
    default: Share,
  },
  [EIconType.NFT_BEGINNER_LEAGUE]: {
    default: NFTBeginnerLeague,
  },
  [EIconType.NFT_AMATEUR_LEAGUE]: {
    default: NFTAmateurLeague,
  },
  [EIconType.NFT_PRO_LEAGUE]: {
    default: NFTProLeague,
  },
  [EIconType.NFT_LEGENDARY_LEAGUE]: {
    default: NFTLegendaryLeague,
  },
  [EIconType.TRAINGLE_BOTTOM]: {
    default: TraingleBottom,
  },
  [EIconType.PRO_LEAGUE_ICON]: {
    default: ProLeagueIcon,
  },
  [EIconType.BEGINNER_LEAGUE_ICON]: {
    default: BeginnerLeagueIcon,
  },
  [EIconType.AMATEUR_LEAGUE_ICON]: {
    default: AmateurLeagueIcon,
  },
  [EIconType.LEGENDARY_LEAGUE_ICON]: {
    default: LegendaryLeagueIcon,
  },
  [EIconType.TELEGRAM_WALLET]: {
    default: TelegramWalletLogo,
  },
  [EIconType.BALL]: {
    default: BallInverse,
    accent: BallActive,
  },
  [EIconType.TONKEEPER_LOGO]: {
    default: TonkeeperLogo,
  },
  [EIconType.EXIT]: {
    default: Exit,
  },
  [EIconType.INFO]: {
    default: Info,
    secondary: InfoSecondary,
    inverse: InfoReverse,
  },
  [EIconType.CARDS_ALL]: {
    default: CardsAll,
  },
  [EIconType.CARDS_SALE]: {
    default: CardsSale,
  },
  [EIconType.CARDS_AUCTION]: {
    default: CardsAuction,
  },
  [EIconType.CARDS_NOT_SALE]: {
    default: CardsNotSale,
  },
  [EIconType.FILTERS]: {
    default: Filters,
    accent: FilterActive,
  },
  [EIconType.CANCEL]: {
    default: CancelIcon,
    inverse: CancelInverse,
  },
  [EIconType.FRIENDS]: {
    default: Friends,
  },
  [EIconType.SHARE_ALT]: {
    default: ShareAlt,
  },
  [EIconType.PVP]: {
    default: PVP,
  },
  [EIconType.PRO]: {
    default: Pro,
  },
  [EIconType.AMATEUR]: {
    default: Amateur,
  },
  [EIconType.PREMIUMS]: {
    default: PremiumS,
  },
  [EIconType.PREMIUMM]: {
    default: PremiumM,
  },
  [EIconType.PREMIUML]: {
    default: PremiumL,
  },
  [EIconType.TOKEN]: {
    default: Token,
  },
  [EIconType.FT_TOKEN]: {
    default: FTToken,
  },
  [EIconType.FTON]: {
    default: Fton,
  },
  [EIconType.TON_TOKEN]: {
    default: TonYellow,
  },
  [EIconType.STAR]: {
    default: Star,
  },
  [EIconType.TROPHY]: {
    default: TrophyDefault,
    accent: Trophy,
  },
  [EIconType.NFT_LITE_LEAGUE]: {
    default: NFTLiteLeague,
  },
  [EIconType.NFT_SEMI_PRO_LEAGUE]: {
    default: NFTSemiProLeague,
  },
  [EIconType.NFT_MIX_ZONE_LEAGUE]: {
    default: NFTMixZoneLeague,
  },
  [EIconType.PRIZE_CARD_EPIC]: {
    default: PrizeCardEpic,
  },
  [EIconType.PRIZE_CARD_RARE]: {
    default: PrizeCardRare,
  },
  [EIconType.PRIZE_CARD_LEGENDARY]: {
    default: PrizeCardLegendary,
  },
  [EIconType.SUCSESS]: {
    default: Sucsess,
  },
  [EIconType.VIDEO]: {
    default: Video,
  },
  [EIconType.LOOTBOX]: {
    default: LootBox,
  },
  [EIconType.EURO]: {
    default: Euro,
  },
  [EIconType.CUP]: {
    default: Cup,
  },
  [EIconType.LOCK]: {
    default: Lock,
  },
  [EIconType.QUESTION]: {
    default: Question,
  },
  [EIconType.GIFT]: {
    default: Gift,
  },
  [EIconType.EURO_TICKET]: {
    default: EuroTicket,
  },
  [EIconType.RECIEVE]: {
    default: Recieve,
  },
  [EIconType.SEND]: {
    default: Send,
  },
  [EIconType.SWAP]: {
    default: Swap,
  },
  [EIconType.STAKE]: {
    default: Stake,
  },
  [EIconType.CHEVRON_UP]: {
    default: ChevronUp,
  },
  [EIconType.CHEVRON_RIGHT]: {
    default: ChevronRight,
  },
  [EIconType.CHEVRON_DOWN]: {
    default: ChevronDown,
  },
  [EIconType.CHECK_WHITE]: {
    default: CheckWhite,
  },
  [EIconType.WINLINE]: {
    default: Winline,
  },
  [EIconType.DAILY_PRIZE]: {
    default: DailyPrize,
  },
  [EIconType.STON]: {
    default: Ston,
  },
  [EIconType.KUCOIN]: {
    default: Kucoin,
  },
  [EIconType.MEXC]: {
    default: Mexc,
  },
  [EIconType.GATE]: {
    default: Gate,
  },
};
