import { EGameLabel } from '@Global/consts';
import { TFantonAppTranslation } from '@Global/i18n';

const RUTranslations: TFantonAppTranslation = {
  'common.stop': 'Стоп',
  roultette: {
    'start-game': 'Старт',
  },
  'daily-roulette': {
    button: 'Призы',
    title: 'Время крутить',
    description: 'Запускай колесо, чтобы выиграть приз',
    prizes: {
      'you-won-ticket-to-name-tournament': `Вы выиграли билет на {{name}} турнир`,
      'you-won-prize': `Вы выиграли {{prize}}`,
      'type-nft-avaliable-for-n-hours': '{{type}} NFT доступен в течение {{n}} дней',
    },
    timeout: 'Следующая попытка через:',
    nothing: {
      title: 'Сегодня ничего..',
      description: 'Удачи в следующий раз',
    },
  },
  'any-player-onboarding': {
    label: 'Новые Single matches',
    description: 'Теперь вы можете выбрать любую позицию для 5 игроков в Single matches',
  },
  'community-onboarding': {
    label: 'Присоединяйтесь к нашему сообществу',
    description: 'Не пропустите последние обновления, турниры и акции Fanton!',
    actions: {
      skip: 'Пропустить',
      join: 'Присоединиться',
    },
  },
  'filters.advanced-settings.label': 'Расширенные настройки',
  'filters.advanced-settings.reset-btn.label': 'Сбросить',
  'common.statistics-for-the-last-n-matches': 'Статистика за последние {{n}} матчей',
  'common.average-for-the-match': 'Среднее значение за матч',
  'common.min': 'мин',
  'common.points': 'Очки',
  'common.tournaments.labels': {
    [EGameLabel.EPL]: 'Англия',
    [EGameLabel.LALIGA]: 'Испания',
    [EGameLabel.LIGA1]: 'Франция',
    [EGameLabel.BUNDESLIGA]: 'Германия',
    [EGameLabel.SERIE_A]: 'Италия',
    [EGameLabel.BRA1]: 'Бразилия',
    [EGameLabel.TOP5]: 'TOP-5 United',
    [EGameLabel.WC2022]: 'Катар 2022',
    [EGameLabel.EURO2024]: 'ЕВРО 2024',
    [EGameLabel.CL]: 'Лига Чемпионов',
    [EGameLabel.RUSSIA]: 'Россия',
    [EGameLabel.CONFERENCE_LEAGUE]: 'Лига Конференций',
    [EGameLabel.EUROPA_LEAGUE]: 'Лига Европы',
    [EGameLabel.MLS]: 'США',
  },
  quizes: {
    'correct-answer-label': 'Верно!',
    'wrong-answer-label': 'Неточный ответ!',
    'skip-label': 'Пропустить',
    'try-again-label': 'Еще раз',
  },
  'common.yes': 'да',
  'common.no': 'нет',
  'common.select-the': 'Выберите',
  'common.tap-on-the': 'Нажмите на',
  'common.tap-here': 'Нажмите здесь',
  'common.out-of': 'из',
  'cjm-onboarding': {
    'warning-title': 'Вы уверены, что хотите продолжать, если не знаете систему подсчета очков?',
    welcome: {
      title: 'Добро пожаловать в Ваш первый турнир',
      action: `Выберите нападающего,`,
      description: ' который наберет больше очков в настоящем футбольном матче, выиграйте приз!',
    },
    how: {
      title: 'Как игроки зарабатывают очки?',
    },
    'captain-instructions': {
      title: 'Поздравляем!',
      'description-part-1': ` Вы собрали всю команду. Выберите капитана, нажав на значок "`,
      'description-highlighted-part-1': 'C',
      'description-part-2': `". Капитан умножает заработанные очки на `,
      'description-highlighted-part-2': '1.2X',
    },
    quizes: {
      forward: {
        questions: {
          first: 'Сколько очков получит нападающий, если забьет 2 гола?',
          second: `Сколько очков наберет нападающий, если он отдаст 2 голевые передачи?`,
          third: `Сколько очков наберет нападающий, если сделает 2 удара в створ ворот?`,
        },
      },
    },
    'forward-label-pt-1': 'Если нападающий забьет',
    'forward-label-pt-2': '2 гола в реальном матче',
    'forward-description-pt-1': 'Он получит',
    'forward-description-pt-2': '140 очков',
    'forward-action': 'Это понятно',
  },
  'scroing.groups': {
    'main-score': 'Основные показатели',
    passes: 'Пасы',
    defensive: 'Защитные действия',
    penalty: 'Пенальти',
    extra: 'Дополнительно',
  },
  'scoring.for-every-{{n}}-minutes-in-the-match': 'За каждые {{n}} минуты на поле',
  'scoring.for-every-{{n}}-passes-in-the-match': 'За каждые {{n}} паса',
  'common.position-scoring': 'Система оценки игрока',
  'position-details.reserve.description':
    'Получает 70% очков за свою позицию, если на поле не вышел игрок из основного состава. Считается в момент до того, как игрок покинул поле',
  'common.positions': {
    goalkeeper: 'Вратарь',
    'goalkeeper-accusative': 'Вратаря',
    forward: 'Нападающий',
    'forward-accusative': 'Нападающего',
    midfielder: 'Полузащитник',
    defender: 'Защитник',
    reserve: 'Замена',
    'reserve-accusative': 'Замену',
    back: 'Защитник',
    'back-accusative': 'Защитника',
    midfield: 'Полузащитник',
    'midfield-accusative': 'Полузащитника',
    BACK: 'ЗАЩИТНИК',
    FORWARD: 'НАПАДАЮЩИЙ',
    GOALKEEPER: 'ВРАТАРЬ',
    MIDFIELD: 'ПОЛУЗАЩИТНИК',
    RESERVE: 'ЗАМЕНА',
  },
  onboarding: {
    items: {
      welcome: {
        header: 'Добро пожаловать в Fanton \nFantasy Football!',
        description: 'Зарабатывайте деньги, увлекаясь футболом',
      },
      team: {
        header: 'Создайте команду',
        description: 'Выберите пять футболистов для каждого матча любимой футбольной лиги',
      },
      leaderboard: {
        header: 'Играйте в турнирах',
        description: 'И соревнуйтесь с другими игроками',
      },
      'card-with-coins': {
        header: 'Выигрывайте реальные деньги!',
        description: 'Начните свой первый турнир прямо сейчас',
      },
      'real-world': {
        header: 'Получайте очки за реальные показатели игроков',
      },
    },
    buttons: {
      skip: 'Пропустить,я профи',
      continue: 'Продолжить',
      play: 'Начать турнир',
    },
  },
  'premium-onboarding': {
    items: {
      'hold-on': {
        header: 'Воу! Вы можете выиграть $400 в нашем премиум-турнире',
        description: 'Решитесь принять вызов?',
      },
      'want-to-win-this-prize': {
        header: 'Хотите выиграть этот приз?',
        description: 'Участвуйте в премиум турнире!',
      },
    },
    buttons: {
      'more-info': 'Подробнее',
      'play-premium': 'Играть в премиум',
      decline: 'Нет',
    },
  },
  'pvp-promo': {
    label: 'Трофеи в PvP ДуэляхP',
    description: 'Сражайтесь в Дуэлях, получайте Трофеи и токен $FTON',
    action: 'Play PVP',
  },
  'okx-promo': {
    label: 'Подключи OKX Wallet, раздели приз 800к $FTON',
    description: '',
    action: 'Участвовать',
  },
  'tonstarter-promo': {
    first: {
      header: 'Эксклюзивное предложение',
      description: 'Запустите Fanton в Telegram и получите билет на премиум-турнир БЕСПЛАТНО',
      action: 'Забрать билет',
    },
    second: {
      header: 'Вас ждет призовой фонд в размере 30 000 долларов',
      description: 'Поощрение от Fanton x Tonstarter',
      action: 'Участвовать',
    },
    third: {
      header: 'Призовой фонд $30 000 токенов $FTON',
      action: 'Участвовать',
    },
    'open-league': {
      header: 'THE OPEN LEAGUE',
      description: 'Пройдите квест от Fanton и получите памятную Soulbound NFT',
      action: 'Join',
    },
  },
  'single-match-filters': {
    'main-events': 'Главные события',
    'single-games': 'Одиночные матчи',
  },
  'player-stat-values': {
    assist: 'Голевые передачи',
    'accurate-passes': 'Точные передачи',
    'accurate-crosses': 'Точные длинные передачи',
    'big-chances-created': 'Созданы большие моменты',
    'big-chances-missed': 'Упущенные моменты',
    'blocked-shots': 'Блокированные удары',
    cleansheet: 'Сухой матч',
    clearances: 'Выносы мяча',
    'clearance-offline': 'Выносы с линии ворот',
    'error-lead-to-goal': 'Ошибка привела к голу',
    'fouls-drawn': 'Количество штрафных очков',
    fouls: 'Фолы',
    goals: 'Голы',
    'goals-conceded': 'Пропущенные голы',
    'hit-woodwork': 'Попадания в штангу',
    interceptions: 'Перехваты',
    'key-passes': 'Ключевые передачи',
    'long-balls-won': 'Точные навесы',
    'minutes-played': 'Сыгранные минуты',
    'miss-pass': 'Неточные передачи',
    'own-goals': 'Автоголы',
    'penalties-won': 'Забитый пенальти',
    'penalties-saved': 'Отбитые пенальти',
    'penalties-misses': 'Незабитый пенальти',
    'penalties-committed': 'Фол, повлекший пенальти',
    punches: 'Удары',
    'red-cards': 'Красные карточки',
    saves: 'Сэйвы',
    'saves-inside-box': 'Сэйвы в штрафной площади',
    'show-on-target': 'Кол-во ударов в створ ворот',
    'successful-dribbles': 'Успешный дриблинг',
    trackles: 'Отборы мяча ',
    'average-points': 'Среднее количество очков',
    'yellow-cards': 'Желтые карточки',
  },
  'player-detailed-info.stats-for-last-n-matches': 'Статистика за последние {{n, number}} матчей',
  'common.all': 'Все',
  'common.actions.show-more': 'Показать еще',
  'common.actions.hide': 'Скрыть',
  'common.actions.dont-show-again': 'Больше не показывать',
  'no-games-in-league-placeholder.label': 'В этой лиге нет доступных турниров',
  'no-games-in-league-placeholder.description': 'Попробуйте выбрать другую',
  'common.actions.create-new-team': 'Создать новую команду',
  'common.actions.play-premium': 'Играть в премиум',
  'common-team-n': 'Команда {{n}}',
  'common.errors.system-error': 'Что-то пошло не так. Попробуйте еще раз позже или обратитесь в службу поддержки.',
  'common.stake.bronze': 'Бронзовый',
  'common.stake.silver': 'Серебряный',
  'common.stake.gold': 'Золотой',
  'common.stake.platinum': 'Платиновый',
  'wallet.withdrawal-request-created.message': 'Запрос на вывод средств скоро будет рассмотрен',
  'wallet-page.title': 'Баланс',
  'wallet-page.total': 'Общий баланс',
  'wallet-page.receive': 'Пополнить',
  'wallet-page.send': 'Вывести',
  'wallet-page.swap': 'Обменять',
  'wallet-page.stake': 'Стейкнуть',
  'wallet-page.not-enough-money-msg': 'Недостаточно средств, пополните свой баланс',
  'wallet-page.no-transactions-placeholder': 'Ваши транзакции появятся здесь',
  'wallet-page.tickets.title': 'Мои билеты',
  'wallet-page.stakes.until': 'До',
  'wallet-page.transactions.title': 'История транзакций',
  'wallet-page.tokens.change-to': 'Обменять на',
  'transactions-list.today': 'Сегодня',
  'transactions-list.yesterday': 'Вчера',
  'swap-money-page.title': 'Обмен',
  'swap-money-page.fields.you-send': 'Вы отправляете',
  'swap-money-page.fields.you-receive': 'Вы получаете',
  'swap-money-page.fee': 'Комиссия',
  'swap-money-page.blockchain-fee': 'Комиссия блокчейна',
  'swap-money-page.footnote.provided-by': 'Предоставлено',
  'swap-money-page.footnote.privacy-policy': 'Политика конфиденциальности',
  'swap-money-page.footnote.terms-of-use': 'Условия использования',
  'swap-money-page.connect-wallet-placeholder.description': 'Чтобы продолжить, подключите кошелек',
  'stake-money-page.title': 'Стейкинг',
  'stake-money-page.fields.you-stake': 'Вы стейкаете',
  'select-deposit-method.title': 'Выберите способ оплаты',
  'stake-money-page.radio.days': '{{n}} дней',
  'stake-money-page.stake-class': 'Класс',
  'stake-money-page.minimum-deposit': 'Минимальный депозит',
  'stake-money-page.earn-up-to': 'Заработайте до',
  'stake-money-page.fields.total-stake': 'Общий стейк',
  'stake-money-page.fields.apy': 'Годовая процентная доходность (APY)',
  'stake-money-page.fields.daily-accruals': 'Ежедневные начисления',
  'stake-money-page.fields.claim-date': 'Дата завершения',
  'stake-money-page.fields.expected-income': 'Ожидаемый доход',
  'stake-money-page.fields.withdraw-note': 'Средства могут быть сняты не ранее даты завершения стейкинга',
  'stake-created.placeholder.description': 'Вы сделали {{c}} стейкинг.',
  'stake-money-page.warnings.min-stake': 'Минимальный стейкинг требует {{d}} {{c}}',
  'enter-amount-page.title': 'Введите сумму в TON',
  'common.continue-btn.label': 'Продолжить',
  'common.confirm-btn.label': 'Подтвердить',
  'enter-amount-page.commission': 'комиссия',
  'enter-amount.no-commission': 'Без комиссии',
  'enter-amount-in-currency.errors.not-enough-coins': 'Недостаточно монет',
  'enter-amount-in-currency.errors.insufficient-balance': 'Недостаточно {{c}}',
  'enter-amount-in-currency.errors.daily-limit': '{{amount}} {{currency}} это максимальная сумма в день',
  'select-wallet-page.title': 'Выберите кошелек',
  'select-wallet-page.card-top-up-info':
    'На сумму оплаты пополнится ваш баланс. Если сумма пополнения будет больше стоимости турнира, то в будущем вы сможете эту сумму вывести или оплатить другой турнир. У всех провайдеров разнится минимальная сумма пополнения.',
  'bitcoin-transaction-lasting':
    'Подтверждение платежа в сети BTC может занять до 30 минут. После полного подтверждения транзакции в сети BTC ваш игровой баланс будет пополнен',
  'other-wallet-transfer-page.title': 'Подтверждение платежа',
  'other-wallet-transfer-page.instruction':
    'Чтобы пополнить игровой баланс, скопируйте поля и вставьте их в приложение вашего кошелька',
  'other-wallet-transfer-page.instruction.telegram-stars':
    'В скором времени пополнить баланс Fanton можно будет через валюту Telegram Stars. Мы работаем над этой опцией для всех игроков',
  'other-wallet-transfer-page.amount-in': 'Сумма в',
  'other-wallet-transfer-page.wallet': 'Кошелек',
  'other-wallet-transfer-page.comment': 'Комментарий',
  'other-wallet-transfer-page.comment-instruction':
    'Скопируйте этот комментарий и вставьте его в поле комментария в вашем кошельке, чтобы мы могли отследить транзакцию',
  'other-wallet-transfer-page.return-to-balance': 'Вернуться к балансу',
  'select-withdrawal-wallet-page.wallets.wallet.label': 'Адрес кошелька',
  'withdrawal-request-created.placeholder.label': 'Запрос на вывод средств создан',
  'withdrawal-request-created.placeholder.description':
    'Сумма будет отправлена на ваш кошелек в течение 24 часов. Наш менеджер свяжется с вами в ближайшее время',
  'swap-request-created.placeholder.description': 'Было отправлено на ваш баланс',
  'tg-stars.placeholder.label': '«Звёзды» Telegram',
  'select-withdrawal-wallet-page.no-wallets.text': 'Добавьте кошелек для вывода средств',
  'common.action.ok': 'OK',
  'common.score': 'Счет',
  'common.dates': 'Даты',
  'transaction-operation.money-reward.label': 'Prize',
  'transaction-operation.game-ticket.label': 'Premium tournament',
  'transaction-operation.swap.label': 'Обмен',
  'transaction-status.created-label': 'Создано',
  'transaction-status.approve_required-label': 'Ждет подтверждения',
  'transaction-status.approved-label': 'Подтверждена',
  'transaction-status.started-label': 'Транзакция создана',
  'transaction-status.completed-label.sent': 'Вывод',
  'transaction-status.completed-label.received': 'Пополнение',
  'select-withdrawal-wallet-page.withdrawal-error': 'Кажется, у Вас недостаточно средств в кошельке.',
  'wallets.other-wallet': 'Другие кошельки',
  'neocrypto.payment-succeeded-page.placeholder.label': 'Success!',
  'neocrypto.payment-succeeded-page.placeholder.description-default':
    'We are delighted to inform you\nthat we received your payment',
  'waiting-for-transfer-page.placeholder.description-btc':
    'Подтверждение платежа в сети BTC может занять до 30 минут. После полного подтверждения транзакции в сети BTC ваш игровой баланс будет пополнен',
  'waiting-for-transfer-page.placeholder.description-default': 'Мы ожидаем пополнения баланса',
  'waiting-for-transfer-page.placeholder.label': 'Ожидание перевода',
  'prizes-claim': {
    'title.nft-tier': 'NFT Тир',
    description: {
      'special-card': 'Get special NFT player card',
      'card-avaliable-to': 'Карта будет доступна ',
      'will-be-sent-to-tonkeeper': 'Будет отправлено на ваш Tonkeeper',
      'get-5-cards': 'Get 5 player cards',
      'pack-avaliable-to': 'The pack will be available to',
      'pack-common-tier': 'Pack Common Tier',
      'connect-ton-wallet': 'Чтобы забрать приз,\nподключите TON кошелек!',
      'will-be-credited-to-your-balance': 'Будет зачислен на ваш баланс',
      'ticket-to-x-tournament': 'Билет на турнир {{tournament}}',
      'will-be-sent-to-stonfi': 'Будет зачислено на ваш кошелек. Ожидайте начисление средств',
    },
    action: {
      'get-prize': 'Забрать приз',
    },
  },
  'common.place-plural_one': '{{count}}-е место',
  'common.place-plural_two': '{{count}}-е место',
  'common.place-plural_few': '{{count}}-е местро',
  'common.place-plural_other': '{{count}}-е место ',
  'common.search.placeholder': 'Искать',
  'common.game.statuses.paid': 'Оплачен',
  'common.game.statuses.in-progress': 'Идет',
  'common.game.statuses.completed': 'Завершен',
  'common.game.statuses.get-prize': 'Забрать приз',
  'common.game.statuses.join': 'Участвовать',
  'common.common': 'Обычные',
  'common.tier': 'Tier',
  'common.nft': 'NFT',
  'common.ton-connect.sign-in-btn': 'Подключить кошелек',
  'common.tickets': 'Билеты',
  'prizes.participiants-in-tournament': 'Участники турнира',
  'prizes.guaranteed-prize': 'Гарантия',
  'prizes.info-about-participiants': 'Призы рассчитываются от количества участников турнира',
  'common.ratio': 'Коэфф.',
  'common.price': 'Цена ',
  'common.select-type': 'Выберите тип',
  'common.confirm-information': 'Подтвердите информацию',
  'home.tabs.main.label': 'Главная',
  'home.tabs.nft.label': 'NFT',
  'home.tabs.prizes.label': 'Призы',
  'home.tabs.support.label': 'Поддержка',
  'home.tabs.balance.label': 'Баланс',
  'home.section.tournaments.header': 'ТУРНИРЫ',
  'home.section.tournaments.all-tournaments': 'Все турниры',
  'home.section.tournaments.see-all-btn': 'Посмотреть все',
  'home.section.tournaments.no-games.label': 'Предстоящих турниров нет',
  'home.section.my-tournaments.header': 'МОИ ТУРНИРЫ',
  'home.section.my-tournaments.see-all-btn': 'Посмотреть все',
  'home.section.my-tournaments.placeholder.no-games.label': 'Вы пока не начали ни одну игру',
  'home.section.my-tournaments.placeholder.label': 'Соберите свою команду\nдля участия в турнирах',
  'home.section.my-tournaments.placeholder.action': 'Участвовать в ближайшем турнире',
  'home.slider.prizes.see-all-btn': 'Посмотреть все',
  'home.slider.prizes.close-btn': 'Закрыть',
  'prizelist.prizes.n-place.title': '{{n, number}} место',
  'prizelist.prizes.n-m-places.title': '{{n, number}} - {{m}} место',
  'prizelist.prizes.top-n-%.title': 'Топ {{n, number}}%',
  'prizelist.prizes.n-m-%.title': '{{n, number}}-{{m}}%',
  'prizelist.play-tournaments': 'Участвовать',
  'home.slider.nft-cards.header': 'NFT-КАРТЫ',
  'home.slider.nft-cards.see-all-btn': 'Посмотреть все',
  'tournaments.header.title': 'Турниры',
  'common.free': 'Free',
  'common.tabs.paid.title': 'Премиум',
  'games.tabs.current': 'Текущие',
  'games.tabs.past': 'Прошедшие',
  'cards.nft-cards.header': 'NFT-Карты',
  'cards.nft-cards.buy-cards-btn': 'Купить NFT',
  'cards.nft-cards.placeholder.label': 'У вас нет NFT карт',
  'cards.nft-cards.placeholder.description': 'Купите первую',
  'cards.nft-cards.placeholder.action': 'Купить NFT-карты',
  'cards.tabs.common': 'Обычные',
  'cards.tabs.nft': 'NFT',
  'cards.placeholder.description.hint': 'Попробуйте снова',
  'cards.placeholder.label': 'Ничего не найдено',
  'cards.card-advanced.player-team': 'Команда игрока',
  'cards.card-advanced.opposite-team': 'Команда соперника',
  'cards.card-advanced.avg-pts-in-last-n': 'В среднем за последние {{n, number}} матчей',
  'notifications.header.title': 'Уведомления',
  'notifications.placeholder.label': 'Нет новых уведомлений',
  'notifications.placeholder.description': 'Вы прочитали все \n сообщения',
  'notifications.notification.chip.get-prize': 'Забрать приз',
  'notifications.notification.chip.collect': 'Получить',
  'notifications.notification.default-name': 'Free common pack was awarded',
  'filters.filter.tier.label': 'Tier',
  'filters.filter.position.label': 'Позиция игрока',
  'filters.header.title': 'Фильтр',
  'filters.reset-btn.label': 'Сбросить все',
  'filters.filter.club.label': 'Клуб',
  'filters.filter.country.label': 'Страна',
  'filters.filter.championship.label': 'Чемпионат',
  'filters.aplly-btn.label': 'Применить',
  'filter.apply-btn.label': 'Применить',
  'filter.header.title': 'Фильтр',
  'games.placeholder.label': 'Турниры не найдены',
  'games.placeholder.description-current': 'Поищите турниры в других лигах или\n вернитесь позже',
  'games.placeholder.description-past': 'Турниры, в которых вы поучаствуете,\nпоявятся здесь',
  'games.placeholder.action': 'Доступные турниры',
  'game.tab.prizes': 'Призы',
  'game.tab.team': 'Команда',
  'game.tab.matches': 'Матчи',
  'game.tab.results': 'Результаты',
  'game.participants': 'Участники',
  'game.points-limit': 'Лимит очков',
  'game.my-place': 'Место',
  'game.teams.duplicate-onboarding': {
    header: 'Дублирование команду',
    description: 'Вы можете создать дубликат команды',
    action: 'ОК',
  },
  'position-details.header': 'Информация',
  'position-details.points-detail.goal': 'Гол (без пенальти)',
  'position-details.points-detail.own-goal': 'Автогол',
  'position-details.points-detail.assist': 'Голевая передача',
  'position-details.points-detail.match-without-missing-gt-60': 'Матч без пропущенных (>60 мин)',
  'position-details.points-detail.match-without-missing-lt-60': 'Матч без пропущенных (<60 мин)',
  'position-details.points-detail.earned-penalty': 'Заработанный пенальти',
  'position-details.points-detail.penalty-scored': 'Забитый пенальти',
  'position-details.points-detail.penalty-missed': 'Незабитый пенальти',
  'position-details.points-detail.penalty-saved': 'Отбитый пенальти',
  'position-details.points-detail.fouled-penalty': 'Сфолил на пенальти',
  'position-details.points-detail.match-participation': 'Участие в матче',
  'position-details.points-detail.player-on-field-gt-60': 'Игрок на поле (>60 мин)',
  'position-details.points-detail.first-yellow-card': 'Первая желтая карточка',
  'position-details.points-detail.second-yellow-card': 'Вторая желтая карточка',
  'position-details.points-detail.direct-red-card': 'Прямая красная карточка',
  'position-details.points-detail.team-win': 'Команда выиграла',
  'position-details.points-detail.team-draw': 'Ничья',
  'position-details.points-detail.team-loss': 'Команда проиграла',
  // V3 Scoring system
  'position-details.points-detail.GOALS': 'Голы',
  'position-details.points-detail.ASSISTS': 'Голевые передачи',
  'position-details.points-detail.ACCURATE_PASSES': 'Точные пасы',
  'position-details.points-detail.ACCURATE_CROSSES': 'Точные навесы',
  'position-details.points-detail.KEY_PASSES': 'Ключевые передачи',
  'position-details.points-detail.MISS_PASS': 'Неточные пасы',
  'position-details.points-detail.FOULS_DRAWN': 'Заработанные на себе фолы ',
  'position-details.points-detail.FOULS': 'Фолы',
  'position-details.points-detail.SAVES': 'Сейвы',
  'position-details.points-detail.SAVES_INSIDE_BOX': 'Сейвы внутри вратарской',
  'position-details.points-detail.PUNCHES': 'Отбивание мяча руками',
  'position-details.points-detail.CLEANSHEET': 'Сухой матч ',
  'position-details.points-detail.MINUTES_PLAYED': 'Минут сыграно',
  'position-details.points-detail.INTERCEPTIONS': 'Перехваты',
  'position-details.points-detail.CLEARANCES': 'Выносы',
  'position-details.points-detail.TACKLES': 'Отборы',
  'position-details.points-detail.SHOTS_ON_TARGET': 'Удары в створ',
  'position-details.points-detail.BLOCKED_SHOTS': 'Заблокированные удары',
  'position-details.points-detail.HIT_WOODWORK': 'Попадания в каркас ворот',
  'position-details.points-detail.CLEARANCE_OFFLINE': 'Выносы с линии ворот',
  'position-details.points-detail.SUCCESSFUL_DRIBBLES': 'Успешный дриблинг',
  'position-details.points-detail.PENALTIES_MISSES': 'Незабитый пенальти',
  'position-details.points-detail.PENALTIES_SAVED': 'Отбитый пенальти',
  'position-details.points-detail.PENALTIES_WON': 'Заработанный пенальти',
  'position-details.points-detail.PENALTIES_COMMITTED': 'Фол, повлекший пенальти',
  'position-details.points-detail.LONG_BALLS_WON': 'Точные длинные передачи',
  'position-details.points-detail.REDCARDS': 'Красные карточки',
  'position-details.points-detail.YELLOWCARDS': 'Желтые карточки',
  'position-details.points-detail.OWN_GOALS': 'Автоголы',
  'position-details.points-detail.ERROR_LEAD_TO_GOAL': 'Результативные ошибки',
  'position-details.points-detail.GOALS_CONCEDED': 'Пропущенные голы',
  'position-details.points-detail.BIG_CHANCES_MISSED': 'Упущенный голевой момент',
  'position-details.points-detail.BIG_CHANCES_CREATED': 'Созданный голевой момент',

  'position-details.reserve-player.description':
    'Получает 70% очков за свою позицию, если на поле не вышел игрок из основного состава. Считается в момент до того, как игрок покинул поле',
  'team.action-label.select': 'ВЫБРАТЬ',
  'team.position.goalkeeper': 'ВРТ',
  'team.position.back': 'ЗАЩ',
  'team.position.midfield': 'ПЗЩ',
  'team.position.forward': 'НПД',
  'team.position.reserve': 'РЕЗ',
  'team.position.any': 'ЛЮБОЙ',
  'team.position.any-player': 'ИГРОКА',
  'team.position.any-player.subtitle': 'Игрок',
  'team.payment-status.in-progress': 'Ждем подтверждения оплаты.\nЭто может занять до 10 минут',
  'team.payment-status.success': 'Оплата завершена',
  'team.nft-card-missing': 'Выберите хотя бы одну NFT карточку для участия в NFT турнире',
  'team.select-any-player-header': 'Выберите игрока',
  'team.select-at-least-n-rarity-cards': 'Выберите не меньше {{n, number}} карт {{rarity}}',
  'team.select-up-to-n-rarity-cards': 'Выберите не больше {{n, number}} карт {{rarity}}',
  'team.rarity-cards-are-not-allowed': 'Карты {{rarity}} не допускаются',
  'common.game': 'Турнир',
  'common.or': 'или',
  team: {
    onboarding: {
      text: {
        begginerV2: '- Использование Rare NFT не ограничено\n- Максимум 2 Epic NFT\n- Максимум 1 Legendary NFT',
        beginner: 'Выберите хотя бы одну NFT карточку любой редкости',
        amateur: '- Использование Rare NFT не ограничено\n- Максимум 1 Epic NFT\n- Legendary NFT не допускаются',
        pro: '- Использование Rare, Common и Epic NFT не ограничено \n- Максимум 1 Legendary NFT',
        legendary: 'Для участия выберите хотя бы 2 Legendary NFT',
      },
    },
  },
  'cards.market-status.filters.all-cards': 'Все карты',
  'cards.market-status.filters.on-sale': 'На продаже',
  'cards.market-status.filters.at-auction': 'Аукцион',
  'cards.market-status.filters.not-for-sale': 'Не на продаже',
  'commmon.sort-options.by-price': 'Сорт. по цене',
  'common.my-nft': 'Мои NFT',
  'common.market': 'Маркет',
  'cards.actions.see-on-ggems': 'Купить на Getgems.io',
  'cards.actions.buy-on-ggems': 'Посмотреть Getgems.io',
  'filters.filter.rarity.label': 'Редкость',
  'filters.filter.price.min-price.label': 'Стоимость (мин)',
  'filters.filter.price.max-price.label': 'Стоимость (макс.)',
  'filters.filter.price.enter-price.placeholder': 'Введите цену',
  'team.captain-missing': 'Выберите капитана для участия в турнире',
  'position-details.captain': 'Капитан',
  'team.too-much-players-from-same-team':
    'Вы не можете зарегистрировать команду с более чем {{n, number}} игроками из одного футбольного клуба. Пожалуйста, измените состав.',
  'team.too-much-points': 'Сумма очков всех игроков превышает лимит. Пожалуйста, измените состав.',
  'team.pop-up.auth-required': 'Для регистрации команды в турнире необходимо авторизоваться',
  'game.actions.pay-in-currency': 'Оплатить {{n, number}} {{c}}',
  'game.actions.pay-with-card': 'Оплата картой',
  'game.actions.play-with-this-team': 'Играть с этой командой',
  'game.actions.save-changes': 'Сохранить изменения',
  'game.actions.placeholder.completed': 'Тур уже закончился',
  'game.actions.placeholder.in-progress.line-1': 'Изменить команду уже нельзя.',
  'game.actions.placeholder.in-progress.line-2': 'Тур идет и закончится через:',
  'game.results.placeholder.no-started.label': 'Не так быстро!',
  'game.results.placeholder.no-started.description': 'Результаты будут доступны после\n окончания игровой недели',
  'game.results.placeholder.no-teams.label': 'Нет зарегистрированных команд',
  'game.results.placeholder.no-teams.description': 'В этом туре никто не участвовал',
  'game.results.your-position': 'Вы',
  'game.results.your-place-is': 'Ваше место',
  'game.results.out-m': 'из {{m}}',
  'common.scoring-system': 'Система подсчета очков',
  'game.results.points-label': 'очков',
  'game.prizes.placeholder.no-prize.label-top-n-pct': 'Потрясающе,\nвы вошли в {{n, number}}% лучших',
  'game.prizes.placeholder.no-prize.description-n-teams-beaten': 'И обыграли {{n}} команд',
  'game.prizes.placeholder.no-prize.same-n-usd-prize-in-premium':
    'С той же командой вы могли бы выиграть ${{n}} в нашем Премиум-турнире',
  'game.prizes.placeholder.no-prizes.action': 'Играть снова',
  'game.prizes.placeholder.prizes-claimed.label': 'Призы получены!',
  'game.prizes.placeholder.prizes-ready.label.line-1': 'Поздравляем!',
  'game.prizes.placeholder.prizes-ready.label.line-2': `Вы заняли `,
  'game.prizes.placeholder.prizes-ready.action': 'Забрать приз',
  'game.prizes.placeholder.prizes-claimed.action': 'Играть снова',
  'game.prizes.placeholder.prizes-claimed.description.line-1': 'Вы уже собрали призы этого раунда',
  'game.prizes.placeholder.prizes-claimed.description.line-2': '',
  'game.prizes.placeholder.prizes-ready.description': 'Получите призы!',
  'team-created.placeholder.label': 'Вы зарегистрированы на турнир ',
  'team-created.placeholder.action': 'OK',
  'team-created.placeholder.duplicate-to-premium': 'Дублировать команду в премиум',
  'team-created.placeholder.duplicate-team': 'Дублировать команду',
  'team-created.placeholder.back-to-menu': 'Вернуться в меню',
  'pack-claimed.placeholder.label': 'Призы получены',
  'pack-claimed.placeholder.description.line-1': 'Вы уже собрали карточки',
  'pack-claimed.placeholder.description.line-2': 'этого раунда',
  'pack-claimed.placeholder.action': 'Мои карточки',
  'team-details.total-points.titile': 'Общее количество очков',
  'team-details.total-points.points-label': 'очков',
  'team-details.player.action.title': 'Действия игрока',
  'team-details.player.action.not-applicable': 'Не применимо',
  'community-thumb.label': 'СООБЩЕСТВО',
  'community-thumb.btn.label': 'Перейти',
  'prizes.header.title': 'Призы',
  'support.label': 'Поддержка',
  'support.placeholder.label': 'Обратиться в поддержку',
  'support.placeholder.description': 'Для обращения в поддержку вам придется выйти из игры',
  'support.placeholder.action': 'Обратиться в поддержку',
  'referral-program': {
    header: 'Пригласить друга',
    invited: 'Приглашено',
    'played-free': 'Сыграно бесплатных турниров',
    'played-premium': 'Сыграно премиум турниров',
    played: 'Сыграно',
    tickets: 'Билеты',
    label: 'Приглашайте друзей',
    friends: 'Друзья',
    'awarded-tickets': 'Заработанные билеты',
    description: 'И зарабатывайте $FTON токены',
    'instructions-list': {
      'step-1': '1 $FTON за друга который сыграет в бесплатный турнир',
      'step-2': '10 $FTON за друга который сыграет в премиум турнир',
      'step-3': 'Топ 100 участников делят 1000$ в $FTON токенах',
    },
    actions: {
      share: 'Поделиться ссылкой',
      'copy-to-friends': 'Скопируйте ссылку и отправьте друзьям',
    },
  },
  'common.copied': 'Скопированно',
  'telegram-wallet-info': '«Telegram Wallet» - официальный кошелек от Telegram',
  'balance-repenisment-ton': {
    header: 'Пополнить баланс',
    actions: {
      deposit: 'Пополнить',
      'deposit-in-telegram-wallet': 'Пополнить через Telegram Wallet',
      'pay-another-cryptocurrencies': 'Оплатить в другой криптовалюте',
    },
  },
  'friends-games': {
    header: 'Играть с друзьями',
    'play-with-friends': 'Играть с друзьями',
    'my-tournaments': 'Мои турниры',
    'by-invitation': 'По приглашению',
    'create-new': 'Создать новый турнир',
    'mine-placeholder': {
      description: 'Созданные вами турниры будут отображаться здесь',
    },
    'invited-placeholder': {
      header: 'Турниры по приглашению',
      description: 'Турниры, на которые вы были приглашены, будут отображаться здесь',
    },
    'past-placeholder': {
      header: 'Прошедшие турниры',
      description: 'Здесь будут отображаться турниры, в которых вы играли со своими друзьями',
    },
    banner: {
      label: 'ИГРАЙТЕ С ДРУЗЬЯМИ',
      description: 'Создай турнир и играй в Фэнтази футбол со своими друзьями.',
      action: 'Играть',
    },
  },
  'create-game': {
    header: 'Выберите турнир',
    info: 'Вы можете создать только один турнир в каждой лиге',
  },
  'common.create': 'Создать',
  'game-created': {
    header: 'Поздравляем! Вы создали турнир',
    description: 'Создайте команду и пригласите друзей с которыми хотите посоревноваться',
    action: 'Создать команду',
  },
  'invite-friends': 'Поделиться ссылкой',
  pvp: {
    homeScreenLinkLabel: 'PvP турниры',
    label: 'PvP турниры',
    myPvp: 'Мои PvP',
    pvp: 'PvP',
  },
  'common.firstPlace': '1-ое место',
  leaderboars: {
    label: 'Лидерборды',
    levels: {
      amateur: 'Amateur',
      pro: 'Pro',
      worldClub: 'World Club',
    },
    nextLevel: 'Следующий уровень',
    level: 'Уровень',
    league: 'Лига',
    trophies: 'Трофеи',
    trophies_count_one: 'Трофей',
    trophies_count_few: 'Трофея',
    trophies_count_many: 'Трофеев',
    trophies_count_other: 'Трофеев',
    seasons: 'Сезоны',
    howToEarnTrophies: 'Как заработать трофеи?',
    currentPts: 'Мои трофеи',
    myPts: 'Мои трофеи',
    myPrize: 'Моя награда',
    leaders: 'Лидеры',
    noParticipantsPlaceholder: {
      labelPt1: 'Необходимо ',
      labelPt2: 'чтобы попасть в эту лигу',
      description: 'Зарабатывайте трофеи, играя в турнирах',
    },
    seasonOverPlaceholder: {
      label: '{{name}} окончен!',
      description: 'Узнайте, что вы выиграли',
    },
  },
  'team.captain-not-nft-in-nft-game': 'Капитаном можно назначить только NFT карту',
  tasks: {
    'to-play-in-tournament-with-garantee': 'Чтобы принять участие в турнире с призом {{s}}',
    'to-play-in-tournament': 'Чтобы принять участие в турнире',
    start: 'Перейти',
    'check-status': 'Проверить выполнение',
    continue: 'Продолжить выполнение',
    'start-over': 'Начать выполнение',
    'complete-tasks': 'Выполните задания',
  },
  'ido-promo.action': 'Участвовать',
  'ido-promo.label': 'WELCOME TO $FTON',
  'ido-promo.description': 'Сразись за $85,000 и получи вайтлист $FTON на IDO',
  'common.lootbox': 'Лутбоксы',
  'no-lootboxes-placeholder': {
    label: 'Нет доступных лутбоксов',
    description: 'Мы добавим их совсем скоро',
  },
  'lootboxes-cards-available': 'Доступно карт',
  'lootboxes-box-avaliable-till': 'Бокс доступен до',
  'lootboxes-buy': 'Купить за {{amount}} {{currency}}',
  'lootboxes-chance': 'Шанс',
  'get-card': 'Забрать карту',
  'prize-pool': 'Prize pool',
  'euro2024promo.label': '@WHALE EURO2024 ПРИЗОВОЙ ФОНД $100 000',
  'tickets-to-the': 'Билеты на',
  'next-round': 'следующий раунд',
  leaderboards: 'Лидерборды',
  'how-to-play-winline': 'Правила Winline турнира',
  'winline-tournaments': 'Турниры',
  'playoff-prize': 'ПРИЗ',
  'register-team-with-task': 'Задание',
  'euro-details': 'Детали',
  'euro-n-percents-get-tikcet': 'Топ {{n}}% команд получают билет на следующий раунд',
  'euro-1-n-get-ticker': 'Места 1-{{n}} получают билет на следующий раунд',
  'ticket-to-next-round': 'Билет на следующий тур',
  'whale-cashback-promo': {
    label: 'Играй на ЕВРО в @WHALE',
    description: 'Делай ставки на ЕВРО-2024 и получай до 30% кэшбека в @Whale',
  },
  'euro-teams': 'Команды',
  'euro-no-prize': 'Нет приза',
};

export default RUTranslations;
