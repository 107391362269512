import { EGameLabel } from '@Global/consts';
import { TFantonAppTranslation } from '@Global/i18n';

const ITTranslation: TFantonAppTranslation = {
  'any-player-onboarding': {
    label: 'Nuove partite singole',
    description: 'Ora, hai la possibilità di selezionare qualsiasi posizione del giocatore nelle partite singole',
  },
  'community-onboarding': {
    label: 'Entra nella nostra community',
    description: `Non perderti gli ultimi aggiornamenti, tornei e promozioni di Fanton!`,
    actions: {
      skip: 'Salta',
      join: 'Unisciti',
    },
  },
  'filters.advanced-settings.label': 'Impostazioni avanzate',
  'filters.advanced-settings.reset-btn.label': 'Ripristinare le impostazioni',
  'common.statistics-for-the-last-n-matches': 'Statistiche delle ultime {{n}} partite',
  'common.average-for-the-match': 'Media della partita',
  'common.min': 'min',
  'common.points': 'Punti',
  'common.tournaments.labels': {
    [EGameLabel.EPL]: 'Inghilterra',
    [EGameLabel.LALIGA]: 'Spagna',
    [EGameLabel.LIGA1]: 'Francia',
    [EGameLabel.BUNDESLIGA]: 'Germania',
    [EGameLabel.SERIE_A]: 'Italia',
    [EGameLabel.BRA1]: 'Brasile',
    [EGameLabel.TOP5]: 'TOP-5 United',
    [EGameLabel.WC2022]: 'Qatar 2022',
    [EGameLabel.EURO2024]: 'Euro 2024',
    [EGameLabel.CL]: 'Champions League',
    [EGameLabel.RUSSIA]: 'Russia',
    [EGameLabel.CONFERENCE_LEAGUE]: 'Conference League',
    [EGameLabel.EUROPA_LEAGUE]: 'Europa League',
  },
  quizes: {
    'correct-answer-label': 'Sì, è la risposta corretta',
    'wrong-answer-label': 'Risposta sbagliata!',
    'skip-label': 'Salta',
    'try-again-label': 'Prova ancora',
  },
  'common.yes': 'Si',
  'common.no': 'no',
  'common.select-the': 'Seleziona il',
  'common.tap-on-the': 'Clicca sul',
  'common.tap-here': 'Tocca qui',
  'cjm-onboarding': {
    'warning-title': 'Sei sicuro di voler continuare senza conoscere il sistema di attribuzione dei punteggi?',
    welcome: {
      title: 'Benvenuto al tuo primo torneo',
      action: "Seleziona l'attaccante",
      description: ' che segnerà più gol nella partita di calcio nel mondo reale e vinci il premio!',
    },
    how: {
      title: 'Come fanno i giocatori a guadagnare punti?',
    },
    'captain-instructions': {
      title: 'Congratulazioni!',
      'description-part-1': ` Hai creato l'intera squadra. Seleziona un capitano cliccando sull'icona "`,
      'description-highlighted-part-1': 'C',
      'description-part-2': `". Il capitano moltiplica i punti per `,
      'description-highlighted-part-2': '1.2X',
    },
    quizes: {
      forward: {
        questions: {
          first: `Quanti punti otterrà l'attaccante se segna 2 gol?`,
          second: `Quanti punti ottiene l'attaccante che fornisce 2 assist?`,
          third: `Quanti punti ottiene l'attaccante che effettua 2 tiri in porta?`,
        },
      },
    },
    'forward-label-pt-1': 'Se gli attaccanti segnano 2 gol',
    'forward-label-pt-2': 'in una partita di calcio reale',
    'forward-description-pt-1': 'Otterrà',
    'forward-description-pt-2': '140 pts',
    'forward-action': 'Ho capito',
  },
  'scroing.groups': {
    'main-score': 'Punteggio principale',
    passes: 'Passaggi',
    defensive: 'Difesa',
    penalty: 'Rigori',
    extra: 'Extra',
  },
  'scoring.for-every-{{n}}-minutes-in-the-match': 'Ogni {{n}} minuti di partita',
  'scoring.for-every-{{n}}-passes-in-the-match': 'Ogni {{n}} passaggi nella partita',
  'common.position-scoring': 'Punteggio {{position}}',
  'position-details.reserve.description':
    "Il sostituto è un'assicurazione nel caso in cui uno dei vostri giocatori principali non scenda in campo durante la partita. In questo caso, al posto del giocatore che non ha giocato, riceverete punti dal vostro sostituto, ma con una penalità del 70% dei punti.",
  'common.positions': {
    goalkeeper: 'Portiere',
    forward: 'Attaccante',
    midfielder: 'Centrocampista',
    defender: 'Difensore',
    reserve: 'Panchinaro',
    back: 'Difensore',
    midfield: 'Centrocampista',
    BACK: 'DIFENSORE',
    FORWARD: 'ATTACCANTE',
    GOALKEEPER: 'PORTIERE',
    MIDFIELD: 'CENTROCAMPISTA',
    RESERVE: 'PANCHINARO',
  },
  onboarding: {
    items: {
      welcome: {
        header: 'Benvenuto nel Fantacalcio \nFanton!',
        description: 'Guadagna con la tua passione per il calcio',
      },
      team: {
        header: 'Crea una squadra',
        description: 'Per ogni giornata seleziona cinque giocatori di calcio dai tuoi campionati di calcio preferiti',
      },
      leaderboard: {
        header: 'Play tournaments',
        description: 'And compeat with other players',
      },
      'card-with-coins': {
        header: 'Vinci soldi veri!',
        description: 'Inizia subito il tuo primo torneo',
      },
      'real-world': {
        header: 'Ottieni punti per le prestazioni dei tuoi giocatori nel mondo reale',
        'real-world': 'MONDO REALE',
        goal: 'goal',
        goals: 'Goals',
        assist: 'Assist',
        yellow_card: 'Cartellino giallo',
        time_played: 'Minuti giocati',
        and_more: 'E altro...',
      },
    },
    buttons: {
      skip: 'Salta',
      continue: 'Continua',
      play: 'Inizia torneo',
    },
  },
  'premium-onboarding': {
    items: {
      'hold-on': {
        header: 'Aspetta! Puoi vincere 400 dollari nel nostro Torneo premium',
        description: 'Hai il coraggio di accettare la sfida?',
      },
      'want-to-win-this-prize': {
        header: 'Vuoi vincere questo premio?',
        description: 'Torneo Premium ora!',
      },
    },
    buttons: {
      'more-info': 'Maggiori informazioni',
      'play-premium': 'Gioca ad un torneo premium',
      decline: 'No',
    },
  },
  'pvp-promo': {
    label: 'Trofeos en Duelos PvP',
    description: 'Combate en Duelos, gana Trofeos y tokens $FTON.',
    action: 'Play PVP',
  },
  'tonstarter-promo': {
    first: {
      header: 'OFFERTA ESCLUSIVA',
      description: 'Apri Fanton su Telegram e ottieni un ticket per il torneo Premium GRATUITO',
      action: 'Ottieni il ticket',
    },
    second: {
      header: '30.000 $ di montepremi ti aspettano',
      description: 'Incentivo Fanton x Tonstarter',
      action: 'Partecipa ora',
    },
    third: {
      header: 'Pool de premios de 30,000 $ en tokens $FTON',
      action: 'Partecipa ora',
    },
    'open-league': {
      header: 'THE OPEN LEAGUE',
      description: 'Completa la Fanton Quest e ottieni memorabili NFT Soulbound',
      action: 'Join',
    },
  },
  'single-match-filters': {
    'main-events': 'Eventi principali',
    'single-games': 'Partite singole',
  },
  'player-stat-values': {
    assist: 'Assist',
    'accurate-passes': 'Passaggi precisi',
    'accurate-crosses': 'Cross precisi',
    'big-chances-created': 'Grandi occasioni create',
    'big-chances-missed': 'Grandi occasioni sbagliate',
    'blocked-shots': 'Tiri parati',
    cleansheet: 'Porta invilata',
    clearances: 'Rinvii',
    'clearance-offline': 'Rinvii fuori',
    'error-lead-to-goal': 'Errore che porta al gol',
    'fouls-drawn': 'Falli subiti',
    fouls: 'Falli',
    goals: 'Gol',
    'goals-conceded': 'Gol subiti',
    'hit-woodwork': 'Pali colpiti',
    interceptions: 'Palloni intercettati',
    'key-passes': 'Passaggi chiave',
    'long-balls-won': 'Palle lunghe vinte',
    'minutes-played': 'Minuti giocati',
    'miss-pass': 'Passaggi sbagliati',
    'own-goals': 'Autogol',
    'penalties-won': 'Rigori conquistati',
    'penalties-saved': 'Rigori sbagliati',
    'penalties-misses': 'Penaltis fallados',
    'penalties-committed': 'Rigori concessi',
    punches: 'Punches',
    'red-cards': 'Cartellini rossi',
    saves: 'Salvataggi',
    'saves-inside-box': "Salva all'interno dell'area di rigore",
    'show-on-target': 'Tiri in porta',
    'successful-dribbles': 'Dribbling riusciti',
    trackles: 'Contrasti',
    'average-points': 'Media punti',
    'yellow-cards': 'Cartellini gialli',
  },
  'player-detailed-info.stats-for-last-n-matches': 'Statistiche delle ultime {{n, number}} partite',
  'wallet.withdrawal-request-created.message': 'La richiesta di prelievo è in attesa',
  'wallet-page.title': 'Saldo',
  'wallet-page.total': 'Saldo totale',
  'wallet-page.receive': 'Ricevi',
  'wallet-page.send': 'Invia',
  'wallet-page.swap': 'Cambio',
  'wallet-page.not-enough-money-msg': 'Fondi insufficienti, ricarica il saldo',
  'wallet-page.no-transactions-placeholder': 'Le tue transazioni appariranno qui',
  'wallet-page.tickets.title': 'I miei biglietti',
  'wallet-page.transactions.title': 'Cronologia delle transazioni',
  'wallet-page.tokens.change-to': 'Cambio con',
  'transactions-list.today': 'Oggi',
  'transactions-list.yesterday': 'Ieri',
  'select-deposit-method.title': 'Seleziona il metodo di deposito',
  'enter-amount-page.title': "Inserisci l'importo in TON",
  'common.continue-btn.label': 'Continua',
  'common.score': 'Risultato',
  'common.dates': 'Date',
  'enter-amount-page.commission': 'commissione',
  'enter-amount.no-commission': 'Nessuna commissione',
  'select-wallet-page.title': 'Seleziona wallet',
  'select-wallet-page.card-top-up-info':
    "Il saldo verrà aggiornato in base al prezzo del biglietto. Se l'importo del deposito è superiore al costo del torneo, in futuro potrai prelevare questo somma o pagare un altro torneo. Tutti i provider hanno importi di deposito minimo diversi.",
  'bitcoin-transaction-lasting':
    'La conferma del pagamento nella rete BTC può richiedere fino a 30 minuti. Dopo che la transazione è stata confermata con successo dalla rete BTC, il saldo di gioco verrà aggiornato.',
  'other-wallet-transfer-page.title': 'Conferma del pagamento',
  'other-wallet-transfer-page.instruction':
    "Per aggiornare il saldo di gioco, copiare i campi e incollarli nell'applicazione del proprio wallet",
  'other-wallet-transfer-page.instruction.telegram-stars':
    'Prossimamente sarà possibile ricaricare il proprio saldo Fanton utilizzando la moneta Telegram Stars. Stiamo lavorando a questa opzione per tutti i giocatori.',
  'other-wallet-transfer-page.amount-in': 'Importo in',
  'other-wallet-transfer-page.wallet': 'Wallet',
  'other-wallet-transfer-page.comment': 'Commento',
  'other-wallet-transfer-page.comment-instruction':
    'Copia questo commento e incollalo nel campo dei commenti del tuo portafoglio in modo da poter tracciare la transazione.',
  'other-wallet-transfer-page.return-to-balance': 'Ritorna al saldo',
  'select-withdrawal-wallet-page.wallets.wallet.label': 'Indirizzo del wallet',
  'withdrawal-request-created.placeholder.label': 'La richiesta di prelievo è stata creata',
  'withdrawal-request-created.placeholder.description':
    "L'importo sarà inviato al tuo wallet entro 24 ore. Il nostro manager ti contatterà al più presto",
  'select-withdrawal-wallet-page.no-wallets.text': 'Connect wallet you want to withdraw to',
  'common.action.ok': 'OK',
  'common.actions.play-premium': 'Gioca ad un torneo premium',
  'common.actions.dont-show-again': 'Non mostrare più',
  'transaction-operation.money-reward.label': 'Prize',
  'transaction-operation.game-ticket.label': 'Premium tournament',
  'transaction-status.created-label': 'Created',
  'transaction-status.approve_required-label': 'Waiting for approve',
  'transaction-status.approved-label': 'Approved',
  'transaction-status.started-label': 'Started',
  'transaction-status.completed-label.sent': 'Inviati',
  'transaction-status.completed-label.received': 'Ricevuti',
  'select-withdrawal-wallet-page.withdrawal-error': 'Sembra che tu non abbia abbastanza soldi nel wallet.',
  'wallets.other-wallet': 'Altro wallet',
  'neocrypto.payment-succeeded-page.placeholder.label': 'Success!',
  'neocrypto.payment-succeeded-page.placeholder.description-default':
    'We are delighted to inform you\nthat we received your payment',
  'waiting-for-transfer-page.placeholder.description-btc':
    'La conferma del pagamento nella rete BTC può richiedere fino a 30 minuti. Dopo che la transazione è stata confermata con successo dalla rete BTC, il saldo di gioco verrà aggiornato.',
  'waiting-for-transfer-page.placeholder.description-default': 'Siamo in attesa del ricalcolo del saldo',
  'waiting-for-transfer-page.placeholder.label': 'In attesa del trasferimento',
  'prizes-claim': {
    'title.nft-tier': 'NFT di Tier',
    description: {
      'special-card': 'Get special NFT player card',
      'card-avaliable-to': 'La carta sarà disponibile a partire dal',
      'will-be-sent-to-tonkeeper': 'Saranno inviati al tuo Tonkeeper',
      'get-5-cards': 'Get 5 player cards',
      'pack-avaliable-to': 'The pack will be available to',
      'pack-common-tier': 'Pack Common Tier',
      'connect-ton-wallet': 'Collega il tuo wallet TON\nper ricevere il tuo premio!',
      'will-be-credited-to-your-balance': 'Verrà accreditato sul tuo saldo',
      'ticket-to-x-tournament': 'Ticket per il torneo {{tournament}}',
      'will-be-sent-to-stonfi': `Saranno accreditati sul tuo wallet. Attendi l'arrivo dei fondi`,
    },
    action: {
      'get-prize': 'Ottieni i premi',
    },
  },
  'common.all': 'Tutti',
  'common.place-plural_one': '{{count}}°',
  'common.place-plural_two': '{{count}}°',
  'common.place-plural_few': '{{count}}°',
  'common.place-plural_other': '{{count}}°',
  'common.search.placeholder': 'Cerca un calciatore',
  'common.game.statuses.paid': 'Pagato',
  'common.game.statuses.in-progress': 'In corso',
  'common.game.statuses.completed': 'Conclusa',
  'common.game.statuses.get-prize': 'Ottieni i premi',
  'common.game.statuses.join': 'Partecipa',
  'common.common': 'Common',
  'common.nft': 'NFT',
  'common.tier': 'Tier',
  'common.free': 'Gratuiti',
  'common.tickets': 'Tickets',
  'prizes.participiants-in-tournament': 'Partecipanti al torneo',
  'prizes.guaranteed-prize': 'Premio garantito',
  'prizes.info-about-participiants': 'I premi sono calcolati in base al numero di partecipanti al torneo.',
  'common.ratio': 'Rapporto',
  'common.price': 'Costo',
  'common.ton-connect.sign-in-btn': 'Collega il tuo wallet',
  'common.errors.system-error': "Qualcosa è andato storto. Riprova più tardi o contatta l'assistenza.",
  'home.tabs.main.label': 'Primo',
  'home.tabs.nft.label': 'NFT',
  'home.tabs.prizes.label': 'Premi',
  'home.tabs.support.label': 'Supporto',
  'home.section.tournaments.header': 'TORNEI',
  'home.section.tournaments.all-tournaments': 'Tutti i tornei',
  'home.section.tournaments.see-all-btn': 'Vedi tutti',
  'home.section.tournaments.no-games.label': 'Non ci sono tornei in corso',
  'home.section.my-tournaments.header': 'I MIEI TORNEI',
  'home.section.my-tournaments.see-all-btn': 'Vedi tutti',
  'home.section.my-tournaments.placeholder.no-games.label': 'Non hai ancora iniziato una partita',
  'home.section.my-tournaments.placeholder.label': 'Crea il tuo team per partecipare ai tornei',
  'home.section.my-tournaments.placeholder.action': 'Partecipa al prossimo torneo',
  'home.slider.prizes.see-all-btn': 'Vedi tutti',
  'home.slider.prizes.close-btn': 'Chiudi',
  'prizelist.prizes.n-place.title': '{{n, number}} posto',
  'prizelist.prizes.n-m-places.title': '{{n, number}} - {{m}} places',
  'prizelist.prizes.top-n-%.title': 'Top {{n, number}}%',
  'prizelist.prizes.n-m-%.title': '{{n, number}}-{{m}}%',
  'prizelist.play-tournaments': 'Partecipa al torneo',
  'home.slider.nft-cards.header': 'CARTE NFT',
  'home.slider.nft-cards.see-all-btn': 'Vedi tutti',
  'tournaments.header.title': 'Tornei',
  'common.tabs.paid.title': 'Premium',
  'games.tabs.current': 'In corso',
  'games.tabs.past': 'Terminati',
  'cards.nft-cards.header': 'CARTE NFT',
  'cards.nft-cards.buy-cards-btn': 'Compra una carta NFT',
  'cards.nft-cards.placeholder.label': 'Non hai carte NFT ',
  'cards.nft-cards.placeholder.description': 'Compra la tua prima carta NFT',
  'cards.nft-cards.placeholder.action': 'Compra una carta NFT',
  'cards.tabs.common': 'Common',
  'cards.tabs.nft': 'NFT',
  'cards.placeholder.description.hint': 'Carta non trovata',
  'cards.placeholder.label': 'Carta non trovata',
  'cards.card-advanced.player-team': 'Squadra del calciatore',
  'cards.card-advanced.opposite-team': 'Squadra avversaria',
  'cards.card-advanced.avg-pts-in-last-n': 'Media punti nelle ultime {{n, number}} partite',
  'notifications.header.title': 'Notifiche',
  'notifications.placeholder.label': 'Nessuna nuova notifica',
  'notifications.placeholder.description': 'Hai letto tutti\ni messaggi',
  'notifications.notification.chip.get-prize': 'Receber o prêmio',
  'notifications.notification.chip.collect': 'Collect',
  'notifications.notification.default-name': 'Free common pack was awarded',
  'filters.filter.tier.label': 'Tier',
  'filters.filter.position.label': 'Ruolo',
  'filters.header.title': 'Filtrar',
  'filters.reset-btn.label': 'Redefinir tudo',
  'filters.filter.club.label': 'Clube',
  'filters.filter.country.label': 'País',
  'filters.filter.championship.label': 'Campeonato',
  'filters.aplly-btn.label': 'Aplicar',
  'filter.apply-btn.label': 'Aplicar',
  'filter.header.title': 'Filtri',
  'games.placeholder.label': 'Tornei non trovati',
  'games.placeholder.description-current': 'Scegli tra i tornei in corso o torna\nin un secondo momento',
  'games.placeholder.description-past': 'I tornei a cui hai partecipato vengono\nvisualizzati qui',
  'games.placeholder.action': 'Scegli un torneo',
  'game.tab.prizes': 'Premi',
  'game.tab.team': 'Team',
  'game.tab.matches': 'Partite',
  'game.tab.results': 'Risultati',
  'game.participants': 'Partecipanti',
  'game.points-limit': 'Massimo totale',
  'game.my-place': 'Posto',
  'game.teams.duplicate-onboarding': {
    header: 'Duplica team',
    description: 'Crea un duplicato del comando',
    action: 'ОК',
  },
  'position-details.header': 'Info', // Translation missing
  'position-details.points-detail.goal': 'Goal (non su rigore)',
  'position-details.points-detail.own-goal': 'Autogol',
  'position-details.points-detail.assist': 'Assist',
  'position-details.points-detail.match-without-missing-gt-60': 'Partita senza errrori (>60 min)',
  'position-details.points-detail.match-without-missing-lt-60': 'Partita senza errori (<60 min)',
  'position-details.points-detail.earned-penalty': 'Guadagnare un rigore',
  'position-details.points-detail.penalty-scored': 'Rigore segnato',
  'position-details.points-detail.penalty-missed': 'Rigore parato',
  'position-details.points-detail.penalty-saved': 'Rigore segnato',
  'position-details.points-detail.fouled-penalty': 'Rigore sbagliato',
  'position-details.points-detail.match-participation': 'Partecipazione alla partita',
  'position-details.points-detail.player-on-field-gt-60': 'Giocatore in campo (>60 min)',
  'position-details.points-detail.first-yellow-card': 'Primo cartellino giallo',
  'position-details.points-detail.second-yellow-card': 'Secondo cartellino giallo',
  'position-details.points-detail.direct-red-card': 'Cartellino rosso diretto',
  'position-details.points-detail.team-win': 'Vittoria',
  'position-details.points-detail.team-draw': 'Pareggio',
  'position-details.points-detail.team-loss': 'Sconfitta',
  // V3 Scoring system
  'position-details.points-detail.GOALS': 'Goal',
  'position-details.points-detail.ASSISTS': 'Assist',
  'position-details.points-detail.ACCURATE_PASSES': 'Passaggi precisi',
  'position-details.points-detail.ACCURATE_CROSSES': 'Cross precisi',
  'position-details.points-detail.KEY_PASSES': 'Passaggi chiave',
  'position-details.points-detail.MISS_PASS': 'Passaggi sbagliati',
  'position-details.points-detail.FOULS_DRAWN': 'Falli commessi',
  'position-details.points-detail.FOULS': 'Falli',
  'position-details.points-detail.SAVES': 'Parate',
  'position-details.points-detail.SAVES_INSIDE_BOX': '',
  'position-details.points-detail.PUNCHES': 'Tiri',
  'position-details.points-detail.CLEANSHEET': 'Rete inviolata',
  'position-details.points-detail.MINUTES_PLAYED': 'Minuti giocati',
  'position-details.points-detail.INTERCEPTIONS': 'Palloni intercettati',
  'position-details.points-detail.CLEARANCES': 'Salvataggio',
  'position-details.points-detail.TACKLES': 'Tackle',
  'position-details.points-detail.SHOTS_ON_TARGET': 'Tiri in porta',
  'position-details.points-detail.BLOCKED_SHOTS': 'Tiri parati',
  'position-details.points-detail.HIT_WOODWORK': 'Pali colpiti',
  'position-details.points-detail.CLEARANCE_OFFLINE': 'Salvataggio sulla linea',
  'position-details.points-detail.SUCCESSFUL_DRIBBLES': 'Dribbling riusciti',
  'position-details.points-detail.PENALTIES_MISSES': 'Rigori sbagliati',
  'position-details.points-detail.PENALTIES_SAVED': 'Rigori parati',
  'position-details.points-detail.PENALTIES_WON': 'Rigori segnati',
  'position-details.points-detail.PENALTIES_COMMITTED': 'Rigori commessi',
  'position-details.points-detail.LONG_BALLS_WON': 'Palle lunghe vinte',
  'position-details.points-detail.REDCARDS': 'Cartellini rossi',
  'position-details.points-detail.YELLOWCARDS': 'Cartellini gialli',
  'position-details.points-detail.OWN_GOALS': 'Goal segnati',
  'position-details.points-detail.ERROR_LEAD_TO_GOAL': 'Un errore che porta al gol',
  'position-details.points-detail.GOALS_CONCEDED': 'Goal subiti',
  'position-details.points-detail.BIG_CHANCES_MISSED': 'Grandi occasioni sbagliate',
  'position-details.points-detail.BIG_CHANCES_CREATED': 'Grandi occasioni create',

  'position-details.reserve-player.description':
    'Riceve il 70% dei punti per il suo ruolo se un giocatore della squadra titolare non è entrato in campo. Si considera il momento in cui il giocatore ha lasciato il campo.',
  'team.action-label.select': 'SELEZIONARE',
  'team.position.goalkeeper': 'PT',
  'team.position.back': 'DF',
  'team.position.midfield': 'CM',
  'team.position.forward': 'AT',
  'team.position.reserve': 'RIS',
  'team.position.any': 'QUALSIASI',
  'team.position.any-player': 'GIOCATORE',
  'team.position.any-player.subtitle': 'Giocatore',
  'team.payment-status.in-progress':
    'Siamo in attesa della conferma del\n pagamento. Potrebbero essere necessari\n fino a 10 minuti',
  'team.payment-status.success': 'Payment complete',
  'team.nft-card-missing': 'Scegli almeno una carta NFT per\npartecipare al torneo NFT',
  'team.select-any-player-header': 'Seleziona giocatore',
  'team.select-at-least-n-rarity-cards': 'Selecciona al menos {{n, number}} tarjeta {{rarity}}',
  'team.select-up-to-n-rarity-cards': 'Selecciona hasta {{n, number}} tarjeta {{rarity}}',
  'team.rarity-cards-are-not-allowed': 'No se permiten {{rarity}}',
  'common.or': 'o',
  'common.game': 'Torneo',
  team: {
    onboarding: {
      text: {
        begginerV2: '- Uso illimitato di NFT rari\n- Limite di 2 NFT Epico\n- Limite di 1 NFT Leggendaria',
        beginner: 'Per partecipare, è necessario possedere almeno un NFT di qualsiasi rarità',
        amateur: '- Uso illimitato di NFT rari\n- Limite di 1 NFT Epico\n- Gli NFT leggendari non sono ammessi',
        pro: '- Uso illimitato di NFT Rari, Common ed Epici\n- Limite di 1 carta NFT Leggendaria ',
        legendary: 'Per partecipare, selezionare almeno\n2 NFT leggendari',
      },
    },
  },
  'cards.market-status.filters.all-cards': 'Tutte le carte',
  'cards.market-status.filters.on-sale': 'In vendita',
  'cards.market-status.filters.at-auction': 'Asta',
  'cards.market-status.filters.not-for-sale': 'Non in vendita',
  'commmon.sort-options.by-price': 'Ordina per prezzo',
  'common.my-nft': 'I miei NFT',
  'common.market': 'Market',
  'cards.actions.see-on-ggems': 'Vedi su Getgems.io',
  'cards.actions.buy-on-ggems': 'Acquista su Getgems.io',
  'filters.filter.rarity.label': 'Rarità',
  'filters.filter.price.min-price.label': 'Costo (min)',
  'filters.filter.price.max-price.label': 'Costo (max)',
  'filters.filter.price.enter-price.placeholder': 'Enter price',
  'team.captain-missing': 'Scegli un captaino per iniziare il torneo',
  'position-details.captain': 'Captaino',
  'team.too-much-players-from-same-team':
    'Non è possibile schierare una formazione con più di {{n, number}} giocatori della stessa squadra. Per favore, cambia la formazione',
  'team.too-much-points': 'La somma dei punti dei giocatori supera il punteggio totale. Cambia i giocatori',
  'team.pop-up.auth-required': 'Accedi per partecipare al torneo',
  'game.actions.pay-in-currency': 'Paga {{n, number}} {{c}}',
  'game.actions.play-with-this-team': 'Inizia torneo',
  'game.actions.save-changes': 'Salva le modifiche',
  'game.actions.placeholder.completed': 'Il turno è già terminato',
  'game.actions.placeholder.in-progress.line-1': 'Non è più possibile modificare la squadra. Il ',
  'game.actions.placeholder.in-progress.line-2': 'turno si concluderà tra:',
  'game.results.placeholder.no-started.label': 'Non così in fretta!',
  'game.results.placeholder.no-started.description':
    'I risultati saranno disponibili alla fine del turno\n di campionato.',
  'game.results.placeholder.no-teams.label': 'Non ci sono squadre iscritte',
  'game.results.placeholder.no-teams.description': 'Questo torneo non ha partecipanti',
  'game.results.your-position': 'Tu',
  'game.results.your-place-is': 'Tua posizione',
  'game.results.out-m': 'su {{m}}',
  'common.scoring-system': 'Sistema di punteggio',
  'game.results.points-label': 'pt',
  'game.prizes.placeholder.no-prize.label-top-n-pct': 'Incredibile,\nsei entrato nel top {{n, number}}%',
  'game.prizes.placeholder.no-prize.description-n-teams-beaten': 'E hai battuto {{n}} squadre',
  'game.prizes.placeholder.no-prize.same-n-usd-prize-in-premium':
    'Con la stessa squadra, avresti potuto vincere {{n}} dollari nel nostro Torneo Premium.',
  'game.prizes.placeholder.no-prizes.action': 'Gioca ancora',
  'game.prizes.placeholder.prizes-claimed.label': 'Il premio è stato consegnato!',
  'game.prizes.placeholder.prizes-ready.label.line-1': 'Complimenti!',
  'game.prizes.placeholder.prizes-ready.label.line-2': `Sei arrivato`,
  'game.prizes.placeholder.prizes-ready.action': 'Ottieni i premi',
  'game.prizes.placeholder.prizes-claimed.action': 'Gioca ancora',
  'game.prizes.placeholder.prizes-claimed.description.line-1': 'Hai già ritirato le carte di questo turno',
  'game.prizes.placeholder.prizes-claimed.description.line-2': '',
  'game.prizes.placeholder.prizes-ready.description': 'Ottieni il premio!',
  'team-created.placeholder.label': 'Sei registrato per la 24 giornata di',
  'team-created.placeholder.action': 'OK',
  'team-created.placeholder.duplicate-to-premium': 'Duplica team in premium',
  'team-created.placeholder.duplicate-team': 'Dublica squadra',
  'team-created.placeholder.back-to-menu': 'Torna alla pagina principale',
  'pack-claimed.placeholder.label': 'O prêmio foi entregue',
  'pack-claimed.placeholder.description.line-1': 'Hai già ritirato le carte di questo turno',
  'pack-claimed.placeholder.description.line-2': '',
  'pack-claimed.placeholder.action': 'Watch my cards',
  'team-details.total-points.titile': 'Punti totali per la partita',
  'team-details.total-points.points-label': 'pt',
  'team-details.player.action.title': 'Azioni dei calciatori',
  'team-details.player.action.not-applicable': 'Não aplicável',
  'neocrypto.placeholder.label': 'Verifica del pagamento',
  'neocrypto.placeholder.description': "Attendere il completamento dell'operazione",
  'community-thumb.label': 'COMUNITÀ',
  'community-thumb.btn.label': 'Unisciti',
  'prizes.header.title': 'Premi',
  'support.label': 'Assistenza',
  'support.placeholder.label': 'Contatta l’assistenza',
  'support.placeholder.description': 'Per contattare il servizio di assistenza, è necessario uscire dal gioco',
  'support.placeholder.action': 'Contatta l’assistenza',
  'referral-program': {
    header: 'Invita un amico',
    invited: 'Invitati',
    played: 'Partecipato',
    tickets: 'Ticket',
    friends: 'Amici',
    'awarded-tickets': 'Ticket vinti',
    actions: {
      share: 'Condividi link',
      'copy-to-friends': 'Copia il link e invialo agli amici',
    },
  },
  'common.copied': 'Copiato',
  'telegram-wallet-info': '«Telegram Wallet» - wallet ufficiale di Telegram',
  'balance-repenisment-ton': {
    header: 'Ripristino del saldo',
    actions: {
      deposit: 'Deposita',
      'deposit-in-telegram-wallet': 'Deposita nel wallet di Telegram ',
      'pay-another-cryptocurrencies': "Paga con un'altra criptovaluta",
    },
  },
  'friends-games': {
    header: 'Gioca con gli amici',
    'play-with-friends': 'Gioca con gli amici',
    'my-tournaments': 'I miei tornei',
    'by-invitation': 'Su invito',
    'create-new': 'Crea un nuovo torneo',
    'mine-placeholder': {
      description: 'I tornei creati verranno visualizzati qui',
    },
    'invited-placeholder': {
      header: 'Tornei su invito',
      description: 'I tornei a cui siete stati invitati appariranno qui',
    },
    'past-placeholder': {
      header: 'Tornei conclusi',
      description: 'I tornei che hai disputato con i tuoi amici appariranno qui',
    },
  },
  'create-game': {
    header: 'Scegli il torneo',
    info: 'È possibile creare un solo torneo per ogni lega',
  },
  'common.create': 'Crea',
  'game-created': {
    header: 'Congratulazioni, hai creato un torneo',
    description: 'Crea la tua squadra e invita i tuoi amici a sfidarti.',
    action: 'Crea squadra',
  },
  'invite-friends': 'Invita gli amici',
  pvp: {
    homeScreenLinkLabel: 'Partita PvP',
    label: 'Partita PvP',
    myPvp: 'I miei PvP',
    pvp: 'PvP',
  },
  'common.firstPlace': '1° posto',
  leaderboars: {
    label: 'Classifiche',
    levels: {
      amateur: 'Amateur',
      pro: 'Pro',
      worldClub: 'Club mondiale',
    },
    nextLevel: 'Livello successivo',
    level: 'Livello',
    league: 'Campionato',
    seasons: 'Stagioni',
    howToEarnTrophies: 'Come guadagnare Trofei?',
    currentPts: 'Trofei attuali',
    myPts: 'I miei trofei',
    trophies: 'Trofei',
    trophies_count_other: 'Trofei',
    myPrize: 'I miei premi',
    leaders: 'Leader',
    noParticipantsPlaceholder: {
      labelPt1: 'Hai bisogno di',
      labelPt2: 'punti per entrare in questa lega',
      description: 'Gioca tanti tornei per accedere a questo campionato',
    },
    seasonOverPlaceholder: {
      label: 'La {{name}} è finita',
      description: 'È il momento di ritirare i premi',
    },
  },
  'team.captain-not-nft-in-nft-game': 'Apenas o cartão NFT pode ser selecionado como capitão',
  tasks: {
    'to-play-in-tournament-with-garantee': 'per partecipare ad un torneo con un montepremi di {{s}}',
    'to-play-in-tournament': 'per partecipare ad un torneo',
    start: 'Avvia',
    'check-status': 'Stato di controllo',
    continue: 'Continua con le attività',
    'start-over': 'Inizia le attività',
    'complete-tasks': 'Completa le tasks',
  },
  'ido-promo.action': 'Unisciti',
  'ido-promo.label': 'BENVENUTO IN $FTON',
  'ido-promo.description': 'Partecipa alla campagna per vincere 85.000 dollari e unisciti alla whitelist $FTON su IDO',
  'common.lootbox': 'Lootbox',
  'no-lootboxes-placeholder': {
    label: 'Lootboxes are not available',
    description: 'We will add them soon',
  },
  'lootboxes-cards-available': 'Carta disponibile',
  'lootboxes-box-avaliable-till': 'Box disponibile fino al',
  'lootboxes-buy': 'Compra {{amount}} {{currency}}',
  'lootboxes-chance': 'Possibilità',
  'get-card': 'Ritira carta',
  'prize-pool': 'Pool de premios',
  'euro2024promo.label': '@WHALE EURO2024 MONTEPREMI $100 000',
  'tickets-to-the': 'Tickets per il',
  'next-round': 'prossimo round',
  leaderboards: 'Classifiche',
  'how-to-play-winline': 'Come giocare ad Winline torneo',
  'winline-tournaments': 'Tornei',
  'playoff-prize': 'MONTEPREMI',
  'register-team-with-task': 'Attività',
  'euro-details': 'Dettagli',
  'euro-n-percents-get-tikcet': 'Il{{n}}% delle migliori squadre ottiene il ticket per il prossimo turno',
  'euro-1-n-get-ticker': '1-{{n}} partecipanti ottengono i ticket per il prossimo turno',
  'ticket-to-next-round': 'Ticket per il prossimo round',
  'whale-cashback-promo': {
    label: 'Gioca a EURO-2024 su @WHALE',
    description: 'Scommetti su EURO-2024 e ottieni fino al 30% di cashback su @Whale',
  },
  'euro-teams': 'Squadre',
  'euro-no-prize': 'Nessun premio',
};

export default ITTranslation;
